.image-viewer-modal-arrow {
    display: flex;
    position: absolute;
    width: 30px;
    height: 80px;
}

.image-viewer-modal-arrow:hover {
    cursor: pointer;
    opacity: 50%;
}

.image-viewer-modal-right-arrow {
    float: right;
    margin-left: 630px;
    margin-top: 151.5px;
}

.image-viewer-modal-left-arrow {
    transform: rotate(180deg);
    margin-left: 20px;
    margin-top: -236.5px;
}

.image-viewer-modal-current-image-count {
    margin-top: -60px;
    margin-left: 320px;
}

.image-viewer-modal-background {
    position: absolute;
    fill: #000000;
    opacity: 50%;
    width: 100%;
    height: 100%;
}

.image-viewer-modal-outer-container {
    position: absolute;
    margin: auto;
}