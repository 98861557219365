.header-logo {
    width: 80px;
    height: 80px;
    opacity: 0;
}

.header-logo-background {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 20px;
    top: 20px;
    animation: rotate-in 0.8s ease;
    transition: transform 0.8s ease;
}

.header-logo-foreground {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 20px;
    top: 20px;
    cursor: pointer;
}

#logo-container-checkbox {
    display: none;
}

#logo-container-checkbox:checked ~ .header-logo-background {
    transform: rotate(360deg);
    /* animation: rotate-out 0.8s ease; */
}

@keyframes rotate-in {
    from {
        transform: rotate(0deg);
    }
      to {
        transform: rotate(360deg);
    }
}

@keyframes rotate-out {
    from {
        transform: rotate(0deg);
    }
      to {
        transform: rotate(360deg);
    }
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
}

.nav_links li {
    text-decoration: none;
    list-style: none;
  
    display: inline-block;
    padding: 0px 10px; /* Space between the nav links. Seperate 0px (top/bottom) from 10px (right/left) with a space, NOT A COMMA*/
}

.nav_links {
    font-size: 16pt;
    margin-top: -16px;
}

#resume-container {
    width: 633px;
    height: 1000px;
    margin: auto;
}

#resume-iframe {
    width: 633px;
    height: 1000px;
    margin: auto;
}