.title {
    padding-top: 40px;
    padding-bottom: 10px;
}

.title-flex {
    display: flex;
    gap: 40px;
}

line {
    width: fill;
    margin-top: 10px;
    stroke: black;
}

.line {
    width: fill;
    margin-top: 10px;
}