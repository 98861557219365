a {
    text-decoration: none;
}

.theme-dark-a {
    color:#f5f5f5;
}

p {
    font-size: 16pt;
}

.theme-dark-p {
    color:#d8d8d8;
}

h1 {
    font-size: 36pt;
    font-weight: 400;
}

.theme-dark-h1 {
    color:#f3f3f3;
}

h1.decor-text {
    font-size: 96pt;
    font-weight: 500;
}

.theme-dark-h1-decor-text {
    color:#f5f5f5;
}

h2 {
    font-size: 24pt;
    font-weight: 600;
}

.theme-dark-h2 {
    color:#ececec;
}

.theme-dark-svg {
    filter: brightness(2)
}

.theme-light-svg {
    filter: brightness(0)
}

.theme-dark-line-svg {
    stroke: white;
}

#main {
    margin: 0px 200px;
}

@media only screen and (max-width: 750px) {
    #main {
        margin: 0px 10px;
    }
}

body {
    background: linear-gradient(-45deg, #ff5151, #ffc17a, #ff966d, #ff8a6d);
    /* orange bg: background: linear-gradient(-45deg, #ffddb6, #ff9a72, #ffd8bb, #ffe0b7); */
    /* background: linear-gradient(-45deg, #824dff, #5f9fff, #4274d3, #e730ff); */
    background-size: 400% 400%;
    animation: gradient 30s ease infinite;
}

.theme-dark-body {
    background: linear-gradient(-45deg, #120f2b, #130e2b, #0f122b, #0c1936);
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}