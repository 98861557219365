.decor-text {
    margin-top: -60px;
    margin-bottom: -10px;
}

#about-me-flex {
    display: flex;
    justify-content: space-between;
}

#about-me-details {
    width: 50%;
    min-width: 633px;
}

#skills {
    text-align: right;
    display: inline;
    min-width: 633px;
}

.skills-icon-container {
    display: flex;
    gap: 20px;
    float: right;
    padding-bottom: 40px;
}

.skills-header {
    margin-top: 40px;
    margin-bottom: -10px;
}

.contact-icon {
    width: 30px;
    height: 30px;
    padding-right: 20px
}

@media only screen and (max-width: 1590px) {
    #about-me-flex {
        flex-direction: column;
    }
}